<!--
	Last modified: 2022/04/27 11:00:49
-->
<template>
	<div
		v-if="$scopedSlots.default || Object.entries($attrs).length"
		class="c-long-read-target"
	>
		<slot></slot>
	</div>
</template>

<script>
import * as LongRead from '~/citi-baseline/components/LongReadController';

export default {
	name: 'LongReadTarget',

	props: {
		containerId: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		disabled(value) {
			this[value ? 'removeTarget' : 'addTarget']();
		},
	},

	mounted() {
		!this.disabled && this.addTarget();
	},

	beforeDestroy() {
		this.removeTarget();
	},

	methods: {
		addTarget() {
			const order = [];
			let prevNode = this;
			while (prevNode.$parent) {
				const { children } = prevNode.$parent._vnode;

				!children?.length && order.push(0);
				children?.length &&
					children?.forEach((child, index) => {
						child.componentInstance?._uid === prevNode._uid &&
							order.push(index);
					});

				prevNode = prevNode.$parent;
			}

			order.reverse();
			this.$set(LongRead.data?._targets, this.containerId, {
				id: this.containerId,
				title: this.title,
				visibility: 0,
				inViewport: false,
				order,
			});
		},

		removeTarget() {
			delete LongRead.data._targets[this.containerId];
		},
	},
};
</script>
